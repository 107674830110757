import {
    FETCH_NOTIFICATIONS,
    GET_NOTIFICATION,
    SEND_NOTIFICATION,
    UPDATE_NOTIFICATION,
    DELETE_NOTIFICATION,
    SEND_EMAIL,
    GET_EMAIL_HISTORY
} from "../types/notificationTypes";
import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";
 
const INITIAL_STATE = {
    app: [],
    emails: [],
};

export default (state = INITIAL_STATE, action) => {

    let updatedAppNotifications;

    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            const fetchedNotifications = action.payload.notificationMessages || [];
            console.log('fetchedNotifications', fetchedNotifications);

            return {
                ...state,
                app: fetchedNotifications,
            };
        case UPDATE_NOTIFICATION:
            // Assuming action.payload is the updated notification data
            const updatedNotification = action.payload;
            updatedAppNotifications = state.app.map(notification =>
                notification.id === updatedNotification.id ? updatedNotification : notification
            );
            return {
                ...state,
                app: updatedAppNotifications,
            };
        case DELETE_NOTIFICATION:
            // Assuming action.payload is the ID of the deleted notification
            const deletedNotificationId = action.payload;
            const filteredAppNotifications = state.app.filter(notification =>
                notification.id !== deletedNotificationId
            );
            return {
                ...state,
                app: filteredAppNotifications,
            };
        case SEND_NOTIFICATION:
            // Assuming action.payload is the newly created notification data
            const newNotification = action.payload;
            return {
                ...state,
                app: [newNotification, ...state.app],
            };
        case SEND_EMAIL:
            // Assuming action.payload is the newly created email data
            const newEmail = action.payload;
            return {
                ...state,
                emails: [newEmail, ...state.emails],
            };
        case GET_EMAIL_HISTORY:
            // Assuming action.payload is the array of email history data
            const emailHistoryData = action.payload.emailHistory;
            return {
                ...state,
                emails: emailHistoryData,
            };
        case ENV_CHANGE:
        case LOGOUT_USER:
          return INITIAL_STATE;
        default:
            return state;
    }
};
