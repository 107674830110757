import {
    FETCH_LEADERBOARDS,
    CREATE_LEADERBOARD,
    EDIT_LEADERBOARD,
    DELETE_LEADERBOARD,
    PUBLISH_LEADERBOARD
} from "../types/leaderboardTypes";
import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";

const INITIAL_STATE = {
    // ... (your existing initial state)
    leaderboards: []
};

export default (state = INITIAL_STATE, action) => {
    // ... (your existing helper functions)

    const updateLeaderboard = (state, action) => {
        const leaderboardCopy = [...state.leaderboards];
        const index = leaderboardCopy.findIndex(item => item.id === action.payload.id);
        leaderboardCopy[index] = action.payload;
        return leaderboardCopy;
    };

    const deleteLeaderboard = (state, action) => {
        return state.leaderboards.filter(item => item.id !== action.payload);
    };

    switch (action.type) {
        // ... (your existing cases)

        case FETCH_LEADERBOARDS:
            return {
                ...state,
                leaderboards: action.payload
            };
        case CREATE_LEADERBOARD:
            return {
                ...state,
                leaderboards: [...state.leaderboards, action.payload]
            };
        case EDIT_LEADERBOARD:
            return {
                ...state,
                leaderboards: updateLeaderboard(state, action)
            };
        case DELETE_LEADERBOARD:
            return {
                ...state,
                leaderboards: deleteLeaderboard(state, action)
            };
        case PUBLISH_LEADERBOARD:
            return {
                ...state,
                leaderboards: updateLeaderboard(state, action) // Assuming that publishing updates some fields
            };
        case ENV_CHANGE:
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            return state;
    }
};
