import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";  
import {
    FETCH_PREDICTIONS, FETCH_GAMES, FETCH_ROUND_DATA, FETCH_REPORT, SET_GAME_ID,
    FETCH_ALL_FIXTURES, ADD_GAME, EDIT_GAME, FETCH_REFERRALS, DELETE_GAME,
    CREATE_REFERRAL, EDIT_REFERRAL, SET_BANNERS, REORDER_BANNERS, UPDATE_BANNER, DELETE_BANNERS,
    ADD_BANNER, GET_CONFIG, SET_SEASONS, GET_PARTICIPATION, GET_RATINGS, SET_BUCKETS,
    WAITLIST_STATS, PREDICTION_STATS, FETCH_GOOGLE_ANALYTICS, FETCH_GAME_DETAILS,
    SET_PREDICTION_STATS, RESET_PREDICTION_STATS, SET_STARTED_GAMES, SET_REPORTS, SET_PAST_GAMES, 
    SEARCH_PREDICTIONS, UPDATE_WAITLIST_BLACKLIST
} from "../types/predictTypes";

   const INITIAL_STATE = {
     seasons: [],
     games: [],
     referrals: [],
     report: {},
     GameId: "",
     all_fixtures: [],
     banners: [],
     gamesConfig: {},
     seasonsConfig: {},
     buckets: [],
     waitlistStats: {},
     predictionStats: {},
     participation: {},
     googleAnalyticsData: {},
     gameDetails: {},
     startedGames: [],
     pastGames: [],
     reports: [],
     searchedOnWaitlist: {},
     total: 0
   };



   // eslint-disable-next-line import/no-anonymous-default-export
   export default (state = INITIAL_STATE, action) => {

    const updateGame = (state,action) => {
      const gameCopy = state.games.map(item => item);
      const index = gameCopy.findIndex(item => item.gameid === action.payload.gameid )
      gameCopy[index] = action.payload;
      return gameCopy;
    }
    const deleteGame = (state,action) => {
      const gameCopy = state.games.map(item => item);
      const filteredCopy = gameCopy.filter(item => item.gameid !== action.payload);
      return filteredCopy;
    }
    const updateReferral = (state,action) => {
      const refCopy = state.referrals.map(item => item);
      const index = refCopy.findIndex(item => item.id === action.payload.id)
      refCopy[index] = action.payload;
      return refCopy;
    }

    const reorderBanners = (state, action) => {
      const { bannerOrder } = action.payload; // Assuming the payload contains the new order

      // Create a copy of the current banners array
      const bannersCopy = [...state.banners];

      // Reorder the banners array based on the newPos values in the payload
      bannerOrder.forEach(({ label, newPos }) => {
        const bannerIndex = bannersCopy.findIndex((banner) => banner.label === label);
        if (bannerIndex !== -1) {
          const [movedBanner] = bannersCopy.splice(bannerIndex, 1); // Remove the banner from its current position
          bannersCopy.splice(newPos - 1, 0, { ...movedBanner, pos: newPos }); // Insert the banner at the new position with updated pos
        }
      });

      return bannersCopy.sort((a, b) => a.newPos - b.newPos); // Update the banners array with the new order and sort by newPos
    };


    const updateBanner = (state, updatedBanner) => {
      const updatedBanners = state.banners.map((banner) => {
        if (banner.label === updatedBanner.label) {
          // Replace the banner with the updated one
          return {
            ...banner,
            mobile: updatedBanner.mobile,
            url: updatedBanner.url,
          };
        }
        return banner;
      });

      return updatedBanners;
    };

    const deleteBannerByLabel = (state, label) => {
      // Filter out the banner with the specified label
      const updatedBanners = state.banners.filter((banner) => banner.label !== label);

      // Renumber the banners to ensure no gaps in 'pos'
      const renumberedBanners = updatedBanners.map((banner, index) => ({
        ...banner,
        pos: index + 1,
      }));

      // Sort the banners by 'pos' after renumbering
      renumberedBanners.sort((a, b) => a.pos - b.pos);

      return renumberedBanners;
    };

    const addNewBanner = (state, newBanner) => {
      const newBannerPos = state.banners.length + 1;
      newBanner.pos = newBannerPos;
      return [...state.banners, newBanner];
    };

     switch (action.type) {
        case GET_CONFIG:
            return {
                ...state,
                gamesConfig: action.payload.gamesData,
                seasonsConfig: action.payload.seasonsData,
            }
        case SET_SEASONS:
            return {
                ...state,
                seasons: action.payload,
            }
        case FETCH_REFERRALS:
            return {
                ...state,
                referrals: action.payload,
            }
        case EDIT_REFERRAL:
            return{
                ...state,
                referrals: updateReferral(state,action)
            }
        case FETCH_REPORT:
            return{
                ...state,
                report: action.payload,
            }
        case FETCH_GAMES:
            return{
                ...state,
                games: action.payload,
            }
        case SET_GAME_ID:
            return{
                ...state,
                GameId: action.payload,
            }
        case FETCH_ALL_FIXTURES:
            return{
                ...state,
                all_fixtures: action.payload,
            }
        case ADD_GAME:
            return{
                ...state,
                games: [...state.games, action.payload]
            }
        case EDIT_GAME:
            return{
                ...state,
                games: updateGame(state,action)
            }
        case DELETE_GAME:
            return{
                ...state,
                games: deleteGame(state,action)
            }
        case SET_BANNERS:
            return {
                ...state,
                banners: action.payload
            }
        case REORDER_BANNERS:
            return {
                ...state,
                banners: reorderBanners(state, action)
            }
        case UPDATE_BANNER:
            return {
                ...state,
                banners: updateBanner(state, action.payload)
            }
        case DELETE_BANNERS:
            const { payload: deletedLabel } = action;
            return {
                ...state,
                banners: deleteBannerByLabel(state, deletedLabel),
            };
        case ADD_BANNER:
            return {
                ...state,
                banners: addNewBanner(state, action.payload),
            };
        case GET_PARTICIPATION:
            return {
                ...state,
                participation: {
                  ...state.participation,
                  [action.payload.gameid]: action.payload.data,
                },
            };
        case GET_RATINGS:
            return {
                ...state,
                ratings: action.payload,
            };
        case SET_BUCKETS:
            return {
                ...state,
                buckets: action.payload,
            };
        case WAITLIST_STATS:
            return {
                ...state,
                waitlistStats: action.payload,
            };
        case SET_PREDICTION_STATS:
            return {
                ...state,
                predictionStats: {
                  ...state.predictionStats,
                  [action.payload.gameid]: action.payload.data,
                },
            };
        case RESET_PREDICTION_STATS:
            return {
                ...state,
                predictionStats: {
                  ...state.predictionStats,
                  [action.payload.gameid]: {},
                },
            };
        case FETCH_GOOGLE_ANALYTICS:
          return {
            ...state,
            googleAnalyticsData: {
              ...state.googleAnalyticsData,
              [action.payload.propertyName]: action.payload.data,
            },
          };
        case FETCH_GAME_DETAILS:
          return {
            ...state,
            gameDetails: {
                ...state.gameDetails,
                [action.payload.gameid]: action.payload.data,
            }
          };
        case SET_STARTED_GAMES:
          return {
            ...state,
            startedGames: action.payload,
          };
        case SET_REPORTS:
          return {
            ...state,
            reports: action.payload,
          };
        case SET_PAST_GAMES:
          return {
            ...state,
            pastGames: action.payload,
          };
        case SEARCH_PREDICTIONS:
          return {
            ...state,
            searchedOnWaitlist: action.payload,
          };
        case UPDATE_WAITLIST_BLACKLIST:
          return {
              ...state,
              searchedOnWaitlist: state?.searchedOnWaitlist?.map(user => 
                  parseInt(user.id) === parseInt(action.payload.id)
                      ? { ...user, blacklisted: action.payload.blacklist }  // Update only the blacklisted field for the matching user
                      : user // Return the user as is for non-matching entries
              ),
          };

      case ENV_CHANGE:
      case LOGOUT_USER:
        return INITIAL_STATE;

       default:
            return state;
     }
   };