import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";
import { 
  FETCH_REQUESTS, 
  REFRESH_REQUEST,
  REFRESH_REQUESTS,
  CANCEL_REQUEST,
  FETCH_TRANSFERS,
  INITIAITE_TRANSFER,
  FINALIZE_TRANSFER,
  GET_TRANSFER
} from "../types/transfersTypes";

const INITIAL_STATE = {
  transfers: [],
  requests: [],
  transferDetails: null,
  requestsPagination: { pages: 0, items: 0},
  transfersPagination: { pages: 0, items: 0},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        requests: updateRequestsList(state.requests, action.payload.data),
        requestsPagination: action.payload.pagination,
      };
    
    case REFRESH_REQUESTS:
      return {
        ...state,
        requests: updateRequestsList(state.requests, action.payload.data),
      };
    
    case FETCH_TRANSFERS:
      return {
        ...state,
        transfers: updateTransfersList(state.transfers, action.payload.data),
        transfersPagination: action.payload.pagination,
      };
    
    case REFRESH_REQUEST:
      return {
        ...state,
        requests: refreshRequest(state.requests, action.payload),
      };

    case CANCEL_REQUEST:
      return {
        ...state,
        requests: cancelRequest(state.requests, action.payload),
      };

    case INITIAITE_TRANSFER:
      return {
        ...state,
        transfers: initiateTransfer(state.transfers, action.payload),
      };

    case FINALIZE_TRANSFER:
      return {
        ...state,
        transfers: finalizeTransfer(state.transfers, action.payload),
      };

    case GET_TRANSFER:
      return {
        ...state,
        transferDetails: action.payload,
      };

    case ENV_CHANGE:
    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};

function updateRequestsList(requests, payload) {
  // Create a map of the new requests by their IDs for quick lookup
  const payloadMap = payload.reduce((acc, newRequest) => {
      acc[newRequest.id] = newRequest;
      return acc;
  }, {});

  // Update the existing requests with any matching requests from the payload
  const updatedRequests = requests.map(request => 
      payloadMap[request.id] ? { ...request, ...payloadMap[request.id] } : request
  );

  // Find any new requests in the payload that do not exist in the current state
  const newRequests = payload.filter(newRequest => 
      !requests.some(request => request.id === newRequest.id)
  );

  // Combine the updated requests with any new requests
  return [...updatedRequests, ...newRequests];
}

function updateTransfersList(transfers, payload) {
  // Create a map of the new transfers by their IDs for quick lookup
  const payloadMap = payload.reduce((acc, newTransfer) => {
    acc[newTransfer.id] = newTransfer;
    return acc;
  }, {});

  // Create a map of the current transfers by their IDs for quick lookup
  const existingTransfersMap = transfers.reduce((acc, transfer) => {
    acc[transfer.id] = transfer;
    return acc;
  }, {});

  // Update existing transfers with any matching transfers from the payload
  const updatedTransfers = Object.values(existingTransfersMap).map(transfer =>
    payloadMap[transfer.id] ? { ...transfer, ...payloadMap[transfer.id] } : transfer
  );

  // Find any new transfers in the payload that do not exist in the current state
  const newTransfers = payload.filter(newTransfer =>
    !existingTransfersMap[newTransfer.id]
  );

  // Combine the updated transfers with any new transfers
  const allTransfers = [...updatedTransfers, ...newTransfers];

  // Use a Map to ensure all transfers are unique by ID
  const uniqueTransfersMap = allTransfers.reduce((acc, transfer) => {
    acc[transfer.id] = transfer;
    return acc;
  }, {});

  // Return the unique transfers as an array
  return Object.values(uniqueTransfersMap);
}


// Utility functions for handling state updates
function refreshRequest(requests, payload) {
  return requests.map(request => 
    request.id === payload.id ? { ...request, ...payload } : request
  );
}

function cancelRequest(requests, payload) {
  return requests.map(request => 
    request.id === payload.id ? { ...request, ...payload } : request
  );
}

function initiateTransfer(transfers, payload) {
  return [...transfers, payload];
}

function finalizeTransfer(transfers, payload) {
  return transfers.map(transfer => 
    transfer.id === payload.id ? { ...transfer, ...payload } : transfer
  );
}
