import {FETCH_PROJECT_UPDATES} from "../types/projectUpdateTypes";
import { ENV_CHANGE, LOGOUT_USER } from "../types/authTypes";
 
 const INITIAL_STATE = {
   data: [],
   single: {}
 };
 
 // eslint-disable-next-line import/no-anonymous-default-export
 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case FETCH_PROJECT_UPDATES:
        return {
            ...state,
            data: action.payload,
        }
    //  case FETCH_SINGLE_PROJECT:
    //      return {
    //          ...state,
    //         single: action.payload
    //      }
      case ENV_CHANGE:
      case LOGOUT_USER:
        return INITIAL_STATE;
     default:
       return state;
   }
 };