const BASE_URL = {
  production: "https://mookie-api-prod.herokuapp.com", // "https://mookie-api-prod.herokuapp.com",
  staging: "https://api-test.mookie.app", // "https://mookie-api.herokuapp.com",
  dev: "https://mookie-api.herokuapp.com",
};
  
export const MIXPANEL_KEY = {
  dev: process.env.REACT_APP_MIXPANEL_KEY_DEV,
  staging: process.env.REACT_APP_MIXPANEL_KEY_STAGING,
  production: process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION
};
  
export default BASE_URL;