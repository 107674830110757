import { createStore,applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import logger from "redux-logger";
import reducers from "./reducers";

let store;

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

if (
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("staging")
  ) {
  store = createStore(
    persistedReducer, 
      applyMiddleware(logger)
  );
} else {
  store = createStore(
    persistedReducer,
  );
}


export const persistor = persistStore(store)


export default store;