import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Icon from "./Icons";

export const ErrorToast = ({ message }) => (
  <div className=" flex justify-between p-1">
    <Icon className="icon-32 mr-5" id="alert-icon" width="32px" height="32px" />
    <div className="flex-1">
      <h1 className="fw-normal text-sm mb-1"> Error </h1>
      <p className="co-black text-xs">{message}</p>
    </div>
  </div>
);

ErrorToast.propTypes = {
  message: PropTypes.string.isRequired
};

export const SuccessToast = ({ message }) => (
  <div className=" flex justify-between p-3">
    <Icon className="icon-32 mr-5" id="alert-icon" width="32px" height="32px" />
    <div className="flex-1">
      <h1 className="fw-normal text-sm mb-1"> Success </h1>
      <p className="co-black text-xs">{message}</p>
    </div>
  </div>
);

SuccessToast.propTypes = {
  message: PropTypes.string.isRequired
};

export const WarningToast = ({ message }) => (
  <div className="d-flx-sb-center p-10 eyowo-toast">
    <Icon className="icon-32 mr-5" id="warning-icon" width="32px" height="32px" />
    <div className="flex-1">
      <h1 className="fw-normal text-sm mb-1"> Warning </h1>
      <p className="co-black text-xs">{message}</p>
    </div>
  </div>
);

WarningToast.propTypes = {
  message: PropTypes.string.isRequired
};

export const InfoToast = ({ message }) => (
  <div className="d-flx-sb-center p-3">
    {/* <Icon className="icon-32 mr-5" id="info-icon"width="32px" height="32px" /> */}
    <div className="flex-1">
      <h1 className="fw-normal text-sm mb-1"> Info </h1>
      <p className="co-black text-xs">{message}</p>
    </div>
  </div>
);

InfoToast.propTypes = {
  message: PropTypes.string.isRequired
};

export const toastError = (message) =>
  toast.error(<ErrorToast message={message} />, {
    position: toast.POSITION.TOP_CENTER
  });

export const toastSuccess = (message) =>
  toast.success(<SuccessToast message={message} />, {
    position: toast.POSITION.TOP_CENTER
  });

export const toastWarning = (message) =>
  toast.warning(<WarningToast message={message} />, {
    position: toast.POSITION.TOP_CENTER
  });

export const toastInfo = (message) =>
  toast.info(<InfoToast message={message} />, {
    position: toast.POSITION.TOP_CENTER
  });